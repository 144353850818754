.playDescription {
    white-space: normal;
    padding:5px;
    font-size:10px;
    height:50px;
    background:#eee;
    margin:5px 0;
    border-radius:2.5px;
}
.playingField {
    display: flex;
    justify-content: center;
}

.field {
    background: #67bb67;
    /*background:#ccc;*/
    height: 70px;
    width: 360px;
    border-radius:2.5px;
}
.currentPlay .Arc {
    animation: 2s ease forwards 1 draw;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
.blueberry{
    display: none !important;
}
.field .currentPlay circle, .field .logo image {
    transition: 2s ease;
}
.field .currentPlay line {
    animation: 2s ease forwards 1 draw;
}
.lastPlay circle, .lastPlay line, .lastPlay path {
    opacity:0.5;
    animation: 0s ease forwards 1 draw;
    transition: 0s ease;
}
.field .logo circle {
    fill:white;
    opacity:0.5;
}
.Gamecast{
    width:360px;
    margin: auto;
}
.important-info {
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    align-items: center;
}
.important-info div{
    padding:5px;
}
    .important-info h5 {
        color:#999;
        font-weight:700;
        font-size:10px;
    }
    .important-info h4 {
        font-size: 12px;
    }
    .important-info .divider{
        height:50%;
        width:1px;
        background:#ddd;
        padding:0;
    }
.fieldDesign line{
    stroke:white;
    stroke-width:1px;
}
.fieldDesign .firstDown {
    stroke: yellow;
}
.predictor-section {
    width: 360px;
    margin: 0 auto 20px;
}
.predictor-test .home-percent {
    border-radius: 2.5px 0px 0px 2.5px !important;
    text-align: left;
}
.predictor-test .away-percent {
    border-radius: 0 2.5px 2.5px 0 !important;
    text-align: right;
}
.predictor-test{
    padding:0;
    margin:0;
    width:100%;
    gap:3px;
}
.predictor-section .description {
    text-align: center;
    padding: 5px 0;
}

.Arc.FG, .Arc.Kickoff, .Arc.Punt {
    stroke-dasharray: 5px !important;
}
.currentPlay .Arc.FG, .currentPlay .Arc.Kickoff, .currentPlay .Arc.Punt {
    animation: 2s linear forwards 1 draw;
}