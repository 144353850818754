.qbx svg {
    padding-top: 5px;
    height: 40px;
}
.qbx path {
    transform-origin: center;
    animation: 5s ease forwards 1 dash;
    filter: hue-rotate(0deg) brightness(1);
    fill: white;
}
/*
    .qbx path:nth-of-type(1) {
        transform: rotate3d(832, 784, 619, 816deg) scale(0);
    }

    .qbx path:nth-of-type(2) {
        transform: rotate3d(766, 905, 204, -910deg) scale(0);
    }

    .qbx path:nth-of-type(3) {
        transform: rotate3d(722, 789, 398, 416deg) scale(0);
    }

    .qbx path:nth-of-type(4) {
        transform: rotate3d(967, 906, 74, -397deg) scale(0);
    }

    .qbx path:nth-of-type(5) {
        transform: rotate3d(263, 57, 932, 984deg) scale(0);
    }

    .qbx path:nth-of-type(6) {
        transform: rotate3d(340, 954, 677, -808deg) scale(0);
    }

    .qbx path:nth-of-type(7) {
        transform: rotate3d(395, 614, 274, 157deg) scale(0);
    }

    .qbx path:nth-of-type(8) {
        transform: rotate3d(33, 613, 600, -250deg) scale(0);
    }

@keyframes dash {
    to {
        transform: rotate3d(0, 0, 0, 0deg) scale(1);
        filter: hue-rotate(720deg);
        fill:white;
    }
}*/

.quarterback {
    background-color: white;
    overflow: hidden;
    border-radius: 0px;
    width: calc(100% - 4px);
    border: 2px solid white;
}
.main-info {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
}
.quarterback .details{
    padding-top:0;
}
.mine .quarterback{
    display:none;
}
.qbr-holder{
    min-height:calc(100vh - 100px);
}
.mine .MINE {
    display: block;
}
#playerQBR .MINE .playerImage {
    /*border: 2px solid #999;
    background-color: black;*/
}
.available .MINE {
    display: none;
}
.MINE.quarterback{
    /*border:2px solid black;
    background:black;
    color:white;*/
}
.MINE .BuyButton{
    background-color:#eee;
    color:black;
}
.MINE .BuyButton:hover {
    background: white;
    color: black;
}
.quarterback h2 {
    font-size: 12px;
    text-align: left;
    font-weight: 800;
    text-wrap: nowrap;
    overflow: hidden;
    width:160px;
}
.quarterback h3 {
    font-size: 9px;
    text-align: left;
    font-weight: 400;
    text-wrap: nowrap;
    overflow: hidden;
    width: 160px;
    margin:0;
}
#playerQBR{
    padding-bottom:150px;
}
    #playerQBR .player .currentGoingRate {
        width: 100%;
        min-width: 42px;
        position: relative;
        align-items: center;
        border-radius: 0;
        display: flex;
        gap: 1px;
        padding-right: 5px;
        justify-content: flex-end;
    }
#playerQBR{
    display:grid;
    grid-template-columns: 1fr;
    gap:1px;
}
.col-2 .player{

}
    #playerQBR .playerImage {
        width: 30px;
        height:30px;
        border-radius: 1000px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 2px solid #eee;
        background: white;
    }
.col-1 {
    width: fit-content;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    flex-grow: 1;
}
.col-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}
.col-3 {
    width: fit-content;
    padding: 2px;
}
.Buy {
    padding-right: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.market{
    font-size:10px;
    font-weight:600;
}
.main-info .closed {
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    background: #999;
    color: white;
    margin: 12px 7px;
    margin-left: 0;
}
.BuyButton {
    padding: 4px;
    padding-bottom: 1px;
    background-color: black;
    color: rgba(0, 0, 0, 0.9);
    border-radius: 0;
    cursor: pointer;
    width: 55px;
    text-align: center;
    height: 50%;
    display: flex;
    font-size: 1em;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    font-size: 12px;
    font-weight: 800;
}
.MINE .BuyButton {

}
.BuyButton:hover{
    background:black;
}
/*.quarterback span.up {
    color: green;
}
.quarterback span.down {
    color: #999;
}*/
.quarterback span {
    font-size: 18px;
    padding-bottom: 1px;
    font-weight: bold;
    height: 100%;
    border-radius: 2px;
}
.filters {
    display: flex;
    gap: 0;
    justify-content: center;
    top: 95px;
    position: sticky;
    background: white;
    z-index: 1;
    box-shadow: 0 0 3px 0px #888;
    margin-bottom:10px;
}
.filter{
    background:white;
    padding:10px 0;
    border-radius:0;
    font-size:14px;
    color:#999;
    cursor:pointer;
    font-weight:600;
    flex-grow:1;
    text-align:center;
}
.filter.active{
    background:white;
    color:black;
}
.header{
    overflow:hidden;
}
.cash {
    position: fixed;
    top: 0;
    color: white;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 800;
    font-size: 1.5em;
    height: 50px;
    z-index: 4;
    max-width: 480px;
}
.lastPlayed{
    font-size:9px;
    display:none;
}
.currentGoingRate {
    font-family: "Fugaz One";
    font-size: 18px;
    font-weight: 500;
    color: #666;
}
.update-banner{
    padding:0 10px;
}
.update-banner p {
    width: 100%;
    font-size:12px;
}
.BuyButton.sell {
    width: 127px;
}
.quarterback .BuyButton.sell span{
    font-size:inherit;
    padding:0;
    font-weight:500;
    height:auto;
}
.search {
    padding: 0 20px;
    height: 40px;
    padding-bottom:5px;
    display: flex;
    align-items: center;
    background: black;
    top: 50px;
    position: sticky;
    z-index: 1;
}
.qbx{
    padding:0 20px;
}
#qbx-search {
    padding: 5px 18px;
    border: 0;
    font-size: 16px;
    outline: 0;
    border-radius: 100px 0 0 100px;
    flex-grow: 1;
}
#clearSearch {
    padding: 5px 15px;
    background: #eee;
    border-radius: 0 100px 100px 0;
    color: black;
    font-size: 12px;
    height: 19px;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
}

.quarterback h4{
    margin-bottom:5px;
}

.graph {
    display: flex;
    align-items: flex-end;
    height: 200px;
    position: relative;
    width: 90%;
    background: white;
    margin: auto;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.25);
    padding: 2.5px;
}
    .graph svg {
        width: 100%;
        height: 100%;
    }
.graph .bar {
    width:calc(100% / 18);
    /*background-color: black;*/
    display: flex;
    color: rgb(255, 255, 255);
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    position:relative;
}
.point {
    font-size: 9px;
    padding-bottom: 0;
    font-weight: 700;
    aspect-ratio: 1;
    width: 100%;
    position: absolute;
    background: black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.graph text {
    font-size: 10px;
    font-weight: 700;
    text-anchor: middle;
}
.graph text.faded {
    font-size: 8px;

}
.graph .weekNumber {
    text-anchor: middle;
    font-weight:700;
    font-size:10px;
}