.stats-div {
    display: grid;
    grid-template-columns: 47% 6% 47%;
}
.col{
    display:flex;
    flex-direction:column;
}
.home-stats > div {
    flex-direction: row-reverse;
}
.away-stats > div {
    flex-direction: row;
}
.col > div {
    display: flex;
    height: 85px;
    align-items: center;
    gap: 5px;
    position: relative;
}
.col span {
    background: white;
    z-index: 1;
    padding:3px;
}
.middle {
    text-align: center;
    align-items: center;
    text-transform:uppercase;
    font-weight:600;
    position: relative;
    font-size:8px;
    color:#666;
    transform:translateY(-35px);
}
.middle > div:before{
    content:"";
    position:absolute;
    height:70%;
    background:#ccc;
    top:calc(50%);
    width:0.5px;
    left:0;
    right:0;
    margin:auto;
}
.home-stats{
    text-align:right;
}
.away-stats {
    text-align: left;
}
.stats-div .pic img {
    filter: saturate(1);
    height: 100%;
    object-fit: cover;
}
.stats-div .pic {
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    justify-content: center;
    min-width:45px;
}
.stat {
    text-wrap: pretty;
    font-size: 9px;
}
.words {
    overflow: hidden;
    font-size: 11px;
    min-height: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fullName{
    font-weight:700;
}
.predictor-section{
    margin:0 0 20px;
}
    .predictor-section svg {
        width: 100%;
        width: 100%;
        transform: scale(1);
        opacity: 0;
        transition: 2s ease;
        animation: loadPredictor 2s  forwards;
    }
@keyframes loadPredictor {
    from{
        opacity:0;
        transform:scale(1);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
.animated-path {
    animation: draw 2s ease forwards; /* Adjust duration as needed */
}
@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
    .predictor-section svg text {
        font-family: 'Fugaz One';
        font-weight: 400;
        font-size: 12px;
    }
.predictor-section h2, .toggle-content h2{
    font-size:12px;
    margin:5px;
}
.predictor {
    display: flex;
    padding: 2px 5px;
    border-radius: 100px;
    justify-content: space-between;
    font-size: 10px;
    font-weight: 500;
    width: 95%;
    margin: auto;
}
.description {
    font-size: 10px;
    font-style: italic;
    text-align: right;
    padding-right: 5px;
    margin: 2px auto;
    width: 95%;
}

#confirmation {
    background: green;
    color: white;
    margin: auto;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 700;
    position: fixed;
    top: 100px;
    width: 100%;
    max-width:500px;
    display:none;
    box-shadow: 0 0 3px 0px #002d00;
}

#mainsave {
    background: white;
    border: 0;
    color: black;
    padding: 4px 25px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 20px;
    width: 180px;
}
.banner {
    font-weight: 600;
    background: #888;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: -20px 0 0;
    color: white;
    flex-direction: column;
    padding: 5px;
    position: sticky;
    /* width: 100%; */
    /* max-width: calc(500px -(5px* 2)); */
    top: 100px;
    z-index: 1;
}
.countdown-timer {
    gap: 5px;
    display: flex;
    margin-left: 5px;
    width: 100%;
    justify-content: space-evenly;
    text-transform:lowercase;
}
.countdown-item {
    display: flex;
    flex-direction: column;
    font-size: 30px;
    padding:5px 1px;
    min-width:65px;
    font-weight:800;
}
.countdown-item span{
    font-size:8px;
}

.matchup-picks .counts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    padding:0 3px;
    margin-bottom:30px;
}
    .matchup-picks .home {
        text-align: right;
        padding: 5px 2px;

    }
    .matchup-picks .home > div{
        justify-content: flex-end;
    }

    .matchup-picks .away {
        text-align: left;
        padding: 5px 2px;
    }
    .matchup-picks > div > div > div {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 3px;
    }

    .matchup-picks span {
        display: flex;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
    }
    .wpw.winner-home .home .count{
        background:green;
    }
.wpw.winner-away .away .count {
    background: green;
}
.wpw.winner-home .away .count {
    background: #999;
}

.wpw.winner-away .home .count {
    background: #999;
}
        .matchup-picks span img {
            height: 12px;
            width: 12px;
            filter: grayscale(0);
        }
    .matchup-picks .count {
        background: black;
        color: white;
        display: inline-block;
        margin-bottom: 5px;
        padding: 1px 3px;
        min-width: min-content;
        max-width: 100%;
        transition:0.25s ease;
    }
.home .count {
    transform: translateX(1px);
}
.away .count {
    transform: translateX(-1px);
}
.matchup-picks .nopicks{
    display:block;
    text-align:center;
}