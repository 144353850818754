.AppGameList {
    background: white;
}
.Highlighted {
    background: #fff9e1;
}
    .AppGameList .Games {
        display: flex;
        gap: 20px;
        margin: 20px;
        flex-wrap: wrap;
    }
    .AppGameList .Game {
        background: grey;
        color: white;
        flex-grow: 1;
        width: calc(50% - 10px);
        aspect-ratio: 1 / 1;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        max-height:180px;
    }
.wrong .selected .teamLogo {
    box-shadow: 0 0 0 3px #666 inset !important;
    border: 2px solid white !important;
}
    .AppGameList .Gametitle {
        font-weight: 800;
        font-size: 24px;
    }
    .AppGameList .Gamedescription {
        font-weight: 600;
        font-size: 12px;
        opacity:0.9;
    }
    .AppGameList .Gamelogo img{
        max-height:60px;
        height:auto;
        width:auto;
        max-width:100%;
    }
    .AppGameList .CFP .Gamelogo {
        filter: none;
        height:100%;
        min-width:120px;

    }
    .AppGameList .Game.CFP {
        flex-direction: row;
        background: #C28C19;
        max-height: 150px;
        width: calc(100% - 10px);
    }
    .AppGameList .Game.Wrapped {
        flex-direction: row;
        max-height: 150px;
        padding: 20px;
        width: calc(100% - 10px);
        aspect-ratio: auto;
        background: #cf0000;
    }
.slant {
    background: linear-gradient(170deg, transparent 0%, transparent 50%, white 50%, white 100%);
    opacity: 0.25;
    height: 100%;
    width: 100%;
    max-width: 500px;
    position: fixed;
}
        .AppGameList .Game.CFP .Gamelogo  img{
            max-height:100px;
            
        }
        .AppGameList.leaderboard h2 img{
    height: 20px;
    width: 20px;

        }
    .AppGameList .bubble {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 25px;
        overflow: visible;
        height: 20px;
        width: 20px;
    }
    .AppGameList h2 {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        padding: 1px 10px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    .AppGameList .Gameinfo {
        flex-grow: 1;
        text-align: left;
    }
    .AppGameList .Gamelogo {
        height: 80px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        border-radius: 30px;
        filter: invert(1) brightness(10);
    }
    .AppGameList .Game.B10 {
        background: #0089cf;
    }
    .AppGameList .Game.QBX {
        background: #3dcf19;
    }
.playoff-projection .teamInfo span {
    min-width: 10px;
    justify-content: center;
    font-weight: 800;
}




.TBD.teamLogo img {
    filter: grayscale(1) brightness(1.5);
}

.preseason .details{
    display:none;
}
.preseason .matchup-container{
    padding-bottom:5px;
}

.week-buttons .preseason {
    color: #999;
}
.preseason .inProgress p {
    margin: 5px 0;
}
.game-play {
    font-size: 10px;
    height: 55px;
    width: 310px;
    margin: auto;
    text-align: left;
}
.predictor-test{
    gap:4px;
}
.help li{
    text-align:left;
}
.help ul{
    padding-left:20px;
}
    
.sliderHolder .description{
    margin-top:8px;
    text-align:center;
}
.preseason .inProgress.correct .teams .game-status, .preseason .inProgress.wrong .teams .game-status, .bigten .inProgress .teams .game-status {
    /*display:none;*/
    padding: 0;
    font-size: 30px;
}
.bigten .inProgress.correct .teams .game-status, .bigten .inProgress.wrong .teams .game-status {
    margin-top: 0;
}
.preseason .inProgress .teams, .bigten .inProgress .teams {
    justify-content: center;
}
.prediction-tbd {
    text-align: center;
    margin-bottom: 20px;
}
.preseason .predictor-section {
    /*display: none;*/
}
.power .positive{
    color:green;
}
.power .negative {
    color: #cf0000;
}
.power td.number-col {
    font-family: 'Source Code Pro', monospace;
    font-size: 13px;
    font-weight: 600;
    /*font-family: 'Fugaz One', monospace;
    font-weight: 400;
    color: black;
    font-size: 20px;*/
}
.preseason .game-status-time{
    font-weight:bold;
}
    .power .number-col {
    text-align: right;
    padding: 4px 10px 4px 4px;
    
}
    .game-status-down hr {
    border-style: solid;
    border-color: #999;
    width: 100px;
    border-width: 0;
    border-bottom-width:1px;
    height: 0;

    }
.articleStory p em img {
    width: revert-layer;
}
.fileUpload{
    width:100%;
}
.fileUpload ::file-selector-button {
    padding: 6px 12px;
    background: black;
    color: white;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    font-family: "Libre Franklin";
}
.createStory * {
    font-family: var(--readingFont), sans-serif;
    font-size: 15px;
    line-height: 1.6;
    color: #333;
}
.createStory .ql-snow .ql-picker-label{
    padding-right:15px;
}
.createStory ul {
    padding-left: 20px;
}

.profile-section .createStory .quill li {
    margin-bottom: 10px;
    padding-left: 0;
}
.profile-section .createStory button{
    margin:0;
}
.createStory .ql-picker-options *, .createStory .ql-picker-options, .ql-picker-label {
    font-family: "Libre Franklin", sans-serif;
}
    .createStory .ql-editor{
    min-height: 200px;
}
/*.createStory .quill .ql-toolbar.ql-snow {
    display: flex;
    justify-content: flex-end;
}*/
.createStory .quill .ql-formats {
    display: inline-block;
}
.createStory .quill .ql-formats:nth-of-type(5) {
    display:none;
}
.createStory .quill .ql-toolbar.ql-snow, .createStory .quill .ql-container.ql-snow {
    border: 0;
}
.createStory .quill {
    background: #eee;
    border-radius: 5px;
    overflow: hidden;
    border: 0;
    border-bottom: 3px solid #eee;
    width: 100%;
}
.createStory h1, .profile-section .createStory h2, .createStory h3 {
    text-align: left;
}
.createStory .ql-editor p {
    margin: 0 0 15px 0;
    width: 100%;
    text-align: left;
    font-family: var(--readingFont), sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.profile-section h1 {
    margin-bottom: 10px;
    font-size: 24px;
}
:root {
    --readingFont: "Source Serif 4"
}
.loadingNews{
    padding-top:10px;
    font-weight:500;
}

.page.glow .singleArticle{
    border:2px solid black;
}
.handles{
    overflow:hidden;
}
.singleArticle .credit {
    padding: 0px 5px 5px;
    font-size: 10px;
    text-align: left;
    color: #999;
    display:none;
}
.profile .profile-section h2 {
    margin-bottom: 0px;
}
.news h2, .profile-section h2 {
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
    width: 100%;
}
.singleArticle .date {
    padding: 5px 20px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin:0;
    color:#999;
}
.singleArticle h2 a {
    font-size: 18px;
    font-family: 'Libre Franklin', sans-serif;
}
.singleArticle p img, .singleArticle h2 img {
    width: 50px;
}
.singleArticle a{
    text-decoration:none;
    color:#333;
}
.singleArticle * {
    text-align: left;
}
.singleArticle center, .singleArticle .articleStory video, .singleArticle .articleStory video1, .singleArticle .articleStory photo1, alsosee {
    display: none;
}
.articleStory {
    padding: 5px 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}
    .articleStory *, .page.help p {
        font-family: var(--readingFont), sans-serif;
        font-size: 15px;
        line-height: 1.6;
        color: #333;
    }
    .articleStory ul{
        padding-left:20px;
    }
    .articleStory li{
        margin-bottom:10px;
    }
    .articleStory p {
        margin: 0 0 15px 0;
        width: 100%;
        text-align: left;
        font-family: var(--readingFont), sans-serif;
        font-weight: 400;
        font-size: 15px;
    }
.singleArticle .articleTitle {
    margin-top: 0;
    padding-top: 0;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 20px 0;
}
.singleArticle img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}
.articleDescription {
    text-align: left;
    font-size: 16px;
    padding: 5px 20px;
    font-style: italic;
    font-family: var(--readingFont), sans-serif;
}
.backButton {
    text-align: left;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    margin: auto;
    text-wrap: nowrap;
    overflow-x: scroll;
    background: white;
    border-radius: 0;
    display: flex;
    position: sticky;
    top: 50px;
    z-index: 3;
    box-shadow: 0 0 3px 0px #888;
    overflow-x: hidden;
}
.video.singleArticle {
    padding: 0 0 10px;
}
video {
    max-width: 100%;
}
span.reverse{
    transform:scale(-1, 1);
}
.singleArticle {
    background: white;
    border-radius: 10px;
    margin: 5px 0 0;
    padding: 0 0 20px 0;
    overflow: hidden;
}
.news {
    background: white;
    border-radius: 10px;
    margin: 0;
    padding:1px 0 20px 0;
}
/*
.article:nth-of-type(1), .dateHeadline + .article .content {
padding-top:10px;
}
    .article:nth-of-type(1), .dateHeadline + .article {
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 10px 2px #edeef0;
    margin: 20px 10px;
}
        .article:nth-of-type(1) img, .dateHeadline + .article img {
            display: block;
            width: 100%;
            max-height: 250px;
            height:auto;
            border-radius:0;
        }
    .article:nth-of-type(1) .headline, .dateHeadline + .article .headline {
        font-size: 20px;
        font-weight: 600;
        padding: 0 10px 0;
    }
        .article:nth-of-type(1) .date, .dateHeadline + .article .date {
            display: block;
            font-size: 12px;
            color: #999;
            padding: 8px 10px;
        }
    */
.dateHeadline {
    background: #333;
    color: white;
    padding: 10px;
    text-align: left;
    margin-top: 0;
    font-weight: 600;
    position: sticky;
    top: 48px;
    box-shadow: 0 3px 6px -3px black;
}
.article {
    background: white;
    border-radius: 0px;
    margin: 10px 10px;
    /**/
    overflow: hidden;
    display: flex;
    cursor: pointer;
}
    .article img {
        width: 20%;
        object-fit: cover;
        aspect-ratio: 1 / 1;
        border-radius: 10px;
        margin-right: 10px;
    }
hr {
    border-style: solid;
    border-color: #edeef0;
}
.article .content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
    .article .headline {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
    }
    .articleTitle {
        padding: 10px 10px 0px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
    }
.article .date {
    margin: 0;
    font-size: 12px;
    text-align: left;
    color: #999;
}
.news .credit{
    text-align:left;
    font-size:10px;
    font-style:italic;
    padding:2px 5px;
}

.singleArticle h2 {
    font-size: 18px;
    font-family: 'Libre Franklin', sans-serif;
}
.App {
  text-align: center;
}
.logo {
    margin-bottom:-5px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.page.help p {
    width: 100%;
    font-weight: 400;
    text-align: left;
    margin: 0 0 15px 0;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.header .mainname h1{
      font-family: "Fugaz One";
    font-weight: 500;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body, html {
    background: #edeef0;
    font-family: 'Libre Franklin', sans-serif;
    margin: 0;
}
    body::-webkit-scrollbar {
        display: none;
    }
.page {
    background: #edeef0;
    padding-top: 20px;
    min-height: calc(100vh - 220px);
    padding-bottom: 120px;
    text-align: center;
}
button, * {
    font-family: 'Libre Franklin', sans-serif;
}
.matchup-container{
  /* max-width:400px; */
  background:white;
  margin:20px auto;
  overflow:hidden;
  border-radius:5px;
  padding-top:20px;
}
.date{
  margin:auto;
  margin-bottom:20px;
}

h4{
  font-size:14px;
  font-weight:700;
}
h5{
  font-size:12px;
  font-weight:600;
}
h2, h4, h5, p{
  margin:0;
  text-align:center;
}
h4{
    font-weight:600;
}
h5{
    font-weight:400;
}
p {
    font-size: 10px;
    font-weight: 600;
    margin: 20px 0;
    text-align: center;
    width: 100px;
}
.teams{
  display:flex;
  gap:40px;
  justify-content: center;
  flex-direction:row-reverse;
}
.team img{
  width:60px;
  height:60px;
  object-fit: contain;
  
}
.teamLogo {
    width: 60px;
    height: 60px;
    padding: 30px;
    border-radius: 120px;
    border: 2px solid #eee;
}
.team {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inProgress .team{
    cursor:default;
}
.circle{
  content:"4";
  width:30px;
  height:30px;
  background:#666;
  position:absolute;
  top:104px;
  left:50%;
  transform: translateX(-50%);
  border-radius:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:3px solid #666;
  color:white;
  font-weight:bold;
  filter:saturate(0.66);
}

.marked img{
  filter:saturate(0);

}
.marked .circle {
    background: #bbb;
    border-color: #bbb;
}
.marked .selected img {
    filter: saturate(1);
}
.marked .selected .circle {
    background: #000;
    border-color: #000;
    display:flex;
}
.wrong.marked .selected .circle{
    display:none;
}
.week-buttons {
    margin: auto;
    text-wrap: nowrap;
    overflow-x: scroll;
    background: white;
    border-radius: 0;
    display: flex;
    position: sticky;
    top: 50px;
    z-index: 3;
    box-shadow: 0 0 3px 0px #888;
}
    .week-buttons button {
        background: white;
        border: white;
        padding: 5px;
        margin: 5px;
        cursor: pointer;
        color: black;
    }
.week-buttons::-webkit-scrollbar {
  display: none;
}
.active-week{
  font-weight:bold;
}
span.week-dates {
  font-size: 10px;
}
.unclickable .teams {
  pointer-events: none !important;
}
.unclickable p{
    margin: 10px 0 20px;
}
.bigten .team{
    pointer-events:none;
}
.bigten p {
    font-size: 12px;
    margin: 5px 0 20px;
}
.sliderHolder{
    position:relative;
    min-height:70px;
    width:300px;
    margin:auto;
}
    .sliderHolder input[type="range"] {
        height: 10px;
        border-radius:0 !important;
    }
    .sliderHolder input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
    }
        .unclickable .circle {
    display: none;
}
.wrong .circle {
    display: none;
}
.wrong .teamLogo {
    border: 2px solid #eee !important;
    box-shadow:none !important;
}
.correct .circle {
    display: none;
}
.correct .selected .circle {
    display: flex;
}
.inProgress p{
    margin:10px 0;
}
.box-score{
    display:none;
}
.box-score {
    gap: 10px;
    flex-direction:row-reverse;
}
.inProgress .box-score{
    display:flex;

}
.inProgress .teams {
    gap: 5px;
    justify-content: space-evenly;

}
.possession img{
    height:15px;
}
.nv{
    pointer-events:none;
    min-height:40px;
}
.stats-div{
    pointer-events:none;
}
.sliderHolder h4 {
    margin-bottom: 5px;
}
.nv h4{
    display:none;
}
.floater {
    position: absolute;
    pointer-events: none;
    width: fit-content;
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    border-radius: 0px;
    height: 20px;
    line-height: 20px;
    top: calc(50% - 12px);
    font-family: 'Fugaz One';
    font-weight: 300;
    transition: 0;
    padding: 1px 4px;
    transform: translateX(-50%);
}
.nv .floater {
    top: 0px;
}


.nv input[type="range"]::-webkit-slider-thumb{
    display:none;
}
.home-score, .away-score {
    font-size: 20px;
    font-weight: 700;
    transform: translateY(calc(20px));
    height: fit-content;
    min-width: 30px;
    text-align: center;
}
.game-status {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(calc(32px - 50%));
    height: fit-content;
    font-size: 10px;
    font-weight: 500;
    width:100px;
}
.correct .game-status, .wrong .game-status, .bigten .game-status {
    width: auto;
}
.inProgress .team img{
    width:40px;
    height:40px;
    /*padding:20px;
    border-width:2px;*/
}
.inProgress .teamLogo {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 120px;
    border: 2px solid #eee;
}
.inProgress .circle {
    width: 20px;
    height: 20px;
    top: 48px;
    font-size: 13px;
}
.singleArticle .loader {
    top: auto;
    -webkit-animation: rotate 1s infinite linear;
}
.loader {
    height: 0;
    width: 0;
    padding: 10px;
    border: 3px solid #ccc;
    border-right-color: #888;
    border-radius: 22px;
    -webkit-animation: rotate 0.5s infinite linear;

    position: absolute;
    left: 50%;
    top: calc(54px + 100px + 5%);
}

@keyframes rotate {
    /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
    100% {
        -webkit-transform: rotate(360deg);
    }
}


.header {
    color: white;
    display: flex;
    background: black;
    justify-content: space-between;
    position:sticky;
    top:0;
    height:51px;
    align-items:center;
    z-index:3;
}
.logo img{
    height: 30px;
    width: auto;
    text-align: center;
    padding: 0 10px;
}
.profile {
    text-align: right;
}

.footer {
    margin: auto;
    text-wrap: nowrap;
    overflow-x: hidden;
    background: white;
    border-radius: 0;
    display: flex;
    position: fixed;
    width:100%;
    bottom: 0;
    z-index: 2;
    box-shadow: 0 0 3px 0px #888;
    justify-content: space-around;
    padding:10px 0 25px;
}
    .footer > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        gap: 5px;
        opacity: 0.5;
        width: 25%;
        cursor: pointer;
        justify-content: center;
        text-align: center;
    }
    .footer .active-icon, .footer > div:hover {
        font-weight: 700;
        opacity: 1;

    }
.footer img {
    width: 12px;
}


.details {
    width: 100%;
    font-size: 10px;
    padding: 5px;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.details .open{
transform:rotate(180deg);
}
.details img{
    height:5px;
    
}
.save-section {
    background: #000;
    padding: 5px 0;
    position: fixed;
    width: 100%;
    bottom: 74px;
    max-width: 500px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.wrongGuess {
    background: #999;
    height: 25px;
    width: 25px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
}
.correctGuess {
    background: green;
    height: 25px;
    width: 25px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white !important;
}
.wrong img,  .wrong.marked .selected img{
    filter: saturate(0);
}
.wrong.marked  img {
    filter: saturate(1);
}
.game-status img {
    color: white;
    width: 12px;
    height: 12px;
}
/*
.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
}*/
.toggle-content {
    font-size: 12px;
    text-align: left;
    padding: 5px;
}
.toggle-content {
    white-space: nowrap;
}
.indent-1:before {
    content: "1 -- ";
}

.indent-2:before {
    content: "2 ---- ";
}

.indent-3:before {
    content: "3 ------ ";
}

.indent-4:before {
    content: "4 -------- ";
}

.indent-5:before {
    content: "5 ---------- ";
}

.indent-6:before {
    content: "6 ------------ ";
}

.indent-7:before {
    content: "7 -------------- ";
}

.indent-8:before {
    content: "8 ---------------- ";
}

.indent-9:before {
    content: "9 ------------------ ";
}

.indent-10:before {
    content: "10 -------------------- ";
}

.indent-11:before {
    content: "11 ---------------------- ";
}

.leaderboard h1 {
    font-size: 22px;
    font-weight:700;
    text-align:center;
    padding:10px;
    margin:0;
}
.leaderboard h2 {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 1px 10px 6px;
}
.leaderboard select {
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    margin: 5px;
    outline: 0;
    border: 0;
    display: flex;
}
.leaderButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0px auto 20px;
    border-bottom: 1px solid #ccc;
}
.leaderButton{
    padding:5px 20px;
    background:white;
}
    .leaderButton.current {
        font-weight: 600;
        border-bottom: 3px solid black;
    }
.leaderboard .leaderButton span {
    justify-content: center;
}
.leaderboard select option {
    font-size: 10px;
    min-height:0;
}
.leaderboard, form, .profile-section, .login {
    background: white;
    border-radius: 10px;
    padding: 10px 0px;
}
.leaderboard table{
    border-collapse:collapse;
    width:100%;
}
.leaderboard thead th {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-size: 11px;
    text-transform: uppercase;
    text-align:left;
    padding:4px 4px 4px 10px;
    color:#333;
}
    .leaderboard td {
        font-size: 12px;
        text-align: left;
        padding: 4px 4px 4px 10px;
        color: #666;
        font-weight: 500;
        border-bottom: 1px solid #eee;
    }
    .leaderboard tr:nth-of-type(2n) {
        background: #fafafa;
    }
    .leaderboard img{
        height:25px;
        width:25px;
    }
    .leaderboard span {
        display: flex;
        gap: 12px;
        align-items: center;
    }
    .leaderboard .description {
        font-size: 11px;
        text-align: left;
        padding: 8px 4px 4px 10px;
        margin: 0;
        color: #666;
    }

.loginLogo img{
    max-width:50%;
}
.loginLogo {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page.login {
    background: black;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.login form{
    flex-grow:1;
    border-radius: 10px 10px 0 0 ;
}

.profile-section{
    padding: 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
    .profile-section > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 16px;
        margin: 10px 0 0 ;
        border-radius: 10px;
        width: 100%;
    }
        .profile-section > div img {
            overflow: visible;
            width: auto;
            height: 50px;
            aspect-ratio: 1;
        }
    .profile-section input, .profile-section .disabledField, .profile-section .Field {
        background: #eee;
        padding: 25px 15px 10px;
        padding-top: 25px;
        border: 0;
        border-bottom: 3px solid #eee;
        outline: 0;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        width: -webkit-fill-available;
        text-align: left;
    }
    .profile-section .disabledField {
        color: #666;
        background: #ddd;
        border-bottom: 3px solid #ddd;
    }
    .profile-section input[type="text"]:hover{
        border-bottom: 3px solid #666;
    }
    .Field input{
        padding:10px 0;
    }
    .profile-section input:focus, .profile-section .Field:focus {
        border-bottom: 3px solid #333;
    }
        .title {
            font-size: 10px;
            padding:0 12px;
            transform:translateY(20px);
            color:#666;
        }
.profile-section button {
    margin-top: 20px;
    width: -webkit-fill-available;
    padding: 10px 0;
    background: black;
    color: white;
    font-weight: 700;
    font-size:16px;
    border-radius:5px;
    border:0;
    outline:0;
}
    .profile-section button[disabled]{
        background:#999;
        color:#eee
    }
    .profile.page {
        text-align: center;
    }
#logout{
    background:none;
    outline:0;
    padding:0;
    margin:30px 0 0;
    border:0;
    text-align:center;
}
.login{
    margin:20px 0 0;
    padding:20px 20px 80px;
    border-radius:20px 20px 0 0;
    position:fixed;
    width:calc(100% - 40px);
    left:0;
    bottom:0;
}
.login-background{
    height:100vh;
    width:100vw;
    position:fixed;
    background:black;
}
.login h1{
    text-transform:uppercase;
    font-weight:800;
    color:#666;
    font-size:36px;
}
    .login h1 span{
        font-weight: 800;
        color:black;
    }
    .login p {
        width: 100%;
        font-size: 12px;
        text-align: left;
        color: #666;
    }
    .login button {
        margin-top: 20px;
        width: -webkit-fill-available;
        padding: 10px 0;
        background: black;
        color: white;
        font-weight: 700;
        font-size: 20px;
        border-radius: 5px;
        border: 0;
        outline: 0;
    }

    .colors, .icons{
        display:grid;
        grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
        gap:5px;
        margin:10px 0 ;
    }
.color, .icon {
    background: white;
    aspect-ratio: 1;
    border-radius: 100%;
    position: relative;
    border: 3px solid #eee;
}
    .color.active, .icon.active{
        box-shadow:0 0 0 1px #999;
    }
.icons .icon img{
    width: 66%;
    height: 66%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    aspect-ratio: 1;
    position: absolute;
}

.black {
    background: black;
}
.grey1 {
    background: #AAAAAA;
}
.leaderboard .darkyellow{
    background:transparent;
}

.darkyellow {
    background: white;
}

.red {
    background: #5B0B0B;
}
.red1 {
    background: #D11818;
}
.red2 {
    background: #F7B6B6;
}


.orange {
    background: #7C3600;
}
.orange1 {
    background: #D15B17;
}
.orange2 {
    background: #F7CDB6;
}

.yellow {
    background: #5C470A;
}
.yellow2 {
    background: #D1A317;
}
.yellow3 {
    background: #F7E7B6;
}

.green {
    background: #145C0A;
}
.green2 {
    background: #2DD117;
}
.green3 {
    background: #BDF7B6;
}

.blue {
    background: #0A585C;
}
.blue1 {
    background: #17C8D1;
}
.blue2 {
    background: #B6F4F7;
}

.dblue {
    background: #0A145C;
}

.dblue1 {
    background: #172DD1;
}

.dblue2 {
    background: #B6BDF7;
}

.purple {
    background: #3D0A5C;
}
.purple1 {
    background: #8A17D1;
}
.purple2 {
    background: #DEB6F7;
}

.pinky {
    background: #5A0C32;
}

.pink1 {
    background: #D41C75;
}

.pink2 {
    background: #F6B7D5;
}
.special {
    background: #0076b6;
}
.special1 {
    background: #013369;
}
.special2 {
    background: #D50A0A;
}

.red49 {
    background: #aa0101;
}

.darkred {
    background: #8B0000;
}

.darkorange {
    background: #FF8C00;
}



.darkgreen {
    background: #006400;
}

.darkblue {
    background: #19197d;
}

.darkpurple {
    background: #800080;
}

.pink {
    background: #FFB6C1;
}

.teal {
    background: #008080;
}

.brown {
    background: #A52A2A;
}

.cyan {
    background: #3a49c8;
}

.lime {
    background: #32CD32;
}

.magenta {
    background: #FF00FF;
}

.olive {
    background: #5a7d43;
}

.indigo {
    background: #301a5b;
}

.maroon {
    background: #ca0008;
}

.turquoise {
    background: #40E0D0;
}

.violet {
    background: #EE82EE;
}

.peach {
    background: #FFDAB9;
}
.leaderboard tr td:nth-of-type(1) {
    width: 5px;
    min-width: 5px;
    text-align: center;
}
.power tr td:nth-of-type(1) {
    color:#aaa;
}
.icons .gibbsgrey img {
    filter: drop-shadow(0px 0px 2px #a1a1a1);
}
#actualIcon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
}
    #actualIcon img{
        width:75px;
        height:75px;
    }
    .leaderboard span span {
        height: 30px;
        width: 30px;
        border-radius: 10px;
        justify-content: center;
    }
.correct .date h5, .wrong .date h5 {
    display: none;
}
.correct .date, .wrong .date {
    margin-bottom:5px;
}
.leaderboard span span img{
    height: 20px;
    width: 20px;
}
html, .footer, .login-background {
    max-width: 500px;
    margin: auto;
}
.login {
    max-width: 460px;
    left:auto;
    right:auto;
}
    html{
    box-shadow:0 0 10px 0 #999;

}
#addArrow {
    color: white;
    width: 100%;
    max-width: 500px;
    margin: auto;
    display: flex;
    align-items: center;
}
    #addArrow span {
        padding: 10px;
        font-weight: 600;
        font-size: large;
    }
    #addArrow .arrow {
        width: 50px;
        height: 50px;
        padding: 10px;
        padding-right: 20px;
        transform: scale(1, -1) rotate(40deg);
    }
    #addArrow.Safari .arrow {
        position: fixed;
        bottom: 0;
        transform: scale(1, 1) rotate(45deg);
        left: 0;
        right: 0;
        margin: auto;
    }
    #addArrow.NativeAppBrowser img.arrow {
        display: none;
    }
    #addArrow a{
        color:white;
    }
    .help img {
        height: 25px;
        margin: 0 10px;
    }
.pickProgress {
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    min-width:150px;
    justify-content: flex-start;
}
    .pickProgress line {
        stroke:black;
    }
.save-subsection {
    width: 50%;
    padding: 0 10px;
    display: flex;
    justify-content: center;
}
.flamingo img{
    filter: drop-shadow(0px 0px 1px #000);
}
.matchup-container.dark-news {
    background: #000;
    display: flex;
    color: white;
    padding-bottom: 10px;
    padding: 10px;
    gap: 10px;
    align-items: center;
    text-align:center;
    margin-top:5px;
}
    .matchup-container.dark-news .content .heading{
        font-weight:600;
    }
    .matchup-container.dark-news .content .description {
        text-align:left;
        margin:0;
    }
    .matchup-container.dark-news img {
        height: 30px;
    }