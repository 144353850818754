.playoff-projection {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:hidden;
}
.playoff-projection .round{
    width:320px;
    margin:20px 0;
    position:relative;
}
.playoff-projection .matchup{
    width:100%;


}

.playoff-projection .matchup .bye .team{
   margin-left:40%;
}


.playoff-projection .team:nth-of-type(2n){
    border-right: 2px solid #999;
    padding-right:0;
}
.playoff-projection .team {
    height: 30px;
    justify-content: center;
}
    .playoff-projection .team .teamInfo {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        border-bottom: 2px solid #999;
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 12px;
    }
.playoff-projection .first{
    position:relative;
    width:175px;
}
.playoff-projection .seed {
    color: #aaa;
    opacity: 0.8;
    width: 18px;
    text-align: center;
    display: block;
    font-weight: 500;
}
    .playoff-projection .first:after {
        content: "";
        width: calc(60% + 40px);
        height: 2px;
        background: #999;
        display: block;
        position: absolute;
        bottom: calc(25%);
        left: 100%;
    }


.playoff-projection .first:before{
    content:"";
    width:2px;
    height:calc(30px * 1.5);
    background:#999;
    display:block;
    position:absolute;
    bottom:15px;
    left:calc(160% - 2px + 40px);
}

    .playoff-projection .round:after{
    content:"";
    width:10px;
    height:2px;
    background:#999;
    display:block;
    position:absolute;
    top:calc(60% - 2px);
    left:calc(100% - 1px);
}
    

.playoff-projection .round:nth-of-type(2n-1):before{
    content:"";
    width:2px;
    height:calc(100% + 22px);
    background:#999;
    display:block;
    position:absolute;
    top:calc(60% - 2px);
    left:calc(100% + 9px);
}
    .playoff-projection .bracket{
        position:relative;
        padding-right:20px;
    }
    .playoff-projection .bracket:after {
        content: "";
        width: 10px;
        height: calc(50% - 10px);
        display: block;
        position: absolute;
        top: calc(25% + 10px);
        left: calc(100% - 10px);
        border: 2px solid #999;
        border-left-width: 0;
    }
.playoff-projection .bracket:before{
    content:"";
    width:10px;
    height:2px;
    background:#999;
    display:block;
    position:absolute;
    top:50%;
   left:calc(100%);
}

.table-team {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    color: black;
}