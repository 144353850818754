.wrapped {
    background: #ccc;
    height: 100vh;
    position: fixed;
    width: 100%;
    max-width: 500px;
    transition: 1s ease;
}
    .wrapped .year {
        font-size: 190px;
        font-family: "Fugaz One";
        color: #ffffff80;
        line-height: 100px;
        text-align: right;
        margin: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        animation: fadeIn 2s ease 1 forwards;
    }
    .wrapped .info {
        margin-top: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fadeIn 2s ease 1 forwards;
    }
        .wrapped .info .user, .wrapped .info .picks {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
            .wrapped .info .user .name {
                font-size: 20px;
                color:white;
                font-family: "Fugaz One";
                width:90%;
                text-align:center;
                border-bottom:2px solid white;
            }
            .wrapped .info .user .points {
                font-size: 55px;
                color: white;
                font-family: "Fugaz One";
                text-align: center;
                min-height: 81px;
            }
            .wrapped .info .subtext {
                font-size: 15px;
                color: white;
                text-align: center;
                font-weight:600;
                margin-top:-10px;
            }
            .wrapped .info .picks .percent-circle{
position:relative;
            }
                .wrapped .info .picks .percent-circle .correct {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 40px;
                    color: white;
                    font-family: "Fugaz One";
                }
    .wrapped h1 {
        text-align: center;
        color: #ffffff80;
        font-style: italic;
        margin: 30px 0;
        animation: fadeIn 2s ease 1 forwards;
    }
    .wrapped .teams {
        flex-direction: column;
        gap: 25px;
        align-items: center;
        animation: fadeIn 2s ease 1 forwards;
    }
        .wrapped .teams .rank {
            font-size: 20px;
            color: white;
        }
        .wrapped .teams .name .mascot {
            font-size: 30px;
            color: white;
            font-family: "Fugaz One";
            text-transform: uppercase;
        }
        .wrapped .teams .name .city {
            font-size: 14px;
            color: white;
            opacity:0.8;
            font-family: "Fugaz One";
            margin-top:-10px;
        }
        .wrapped .teams .logo {
            background-position: center center;
            background-size: contain;
            height: 50px;
            width: 50px;
        }
        .wrapped .teams .team {
            position: relative;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width:80%;
            gap:10px;
        }

    .wrapped .loading {
        width: 100px;
        height: 100px;
        border: 10px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 2s linear infinite;
        position:fixed;
        top:calc(33% - 50px);
        left:calc(50% - 50px);
        
    }
    .wrapped circle {
        stroke-dashoffset: 100%;
        transition: 2s ease;
        animation: draw 2s ease 1 forwards;
    }


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}